import { Button, Col, Container, Row } from 'react-bootstrap';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import {
  boolApplicableOpts,
  boolYesNoOpts,
  expenditurePeriods, LegalJurisdiction,
  pressBeginOpts,
  pressEndOpts
} from '@property-folders/common/data-and-text/constants';
import { canonicalisers, composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { CollectionEditor } from './CollectionEditor';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { WrField } from './CommonComponentWrappers';
import { BenefitLineItem, FEE_COLS, FeeLineItem, MarketingTemplateLineItem } from './FeeLineItem';
import './ManualFeesSection.scss';
import type { MarketingTemplateOld, TransactionMetaData } from '@property-folders/contract';
import {
  AdminFeeType,
  DisclosureOfBenefitsType,
  MarketingFeesOptions,
  MaterialisedPropertyData,
  PressBudgetType,
  SaleDetailsType,
  SaleMethod
} from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { WizardDisplayContext } from '../../context/WizardContexts';
import clsJn from '@property-folders/common/util/classNameJoin';
import { TooltipWhenRequired } from '../TooltipWhenDisabled';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { cloneDeep, keyBy, mapValues } from 'lodash';
import { TextClickCheck } from './TextClickCheck';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import {
  getPathParentAndIndex,
  getValidationDefnByPath,
  normalisePathToStrArray
} from '@property-folders/common/util/pathHandling';
import { FormContext } from '../../context/FormContext';
import { RequiredAsterisk } from '../../display/RequiredAsterisk';
import { Form1AndSearchesSection } from './Form1AndSearchesSection';
import { TransactionFeeSection } from './TransactionFeeSection';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { SectionReplacementAnnexure } from '../../display/form/SectionReplacementAnnexure';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { useEntity } from '../../hooks/useEntity';
import { DisclosureOfBenefitTemplateSelector } from './DisclosureOfBenefitTemplateSelector';
import { FormatAct } from '../../display/GuidanceNotes';
import { MarketingTemplate, MarketingTemplateSectionType } from '@property-folders/contract/yjs-schema/entity-settings';
import { MarketingSection } from '../MarketingTemplate/MarketingSection';
import { Maybe } from '@property-folders/common/types/Utility';

type ValidChildren = undefined | string | JSX.Element | JSX.Element[];

const RowReplication = ({ contentA, contentB }: {contentA?: ValidChildren, contentB?: ValidChildren}) => <div className='d-flex w-100'>

  <Container className='px-0'>
    <Row className='mx-0 flex-grow-1'>
      <Col className='px-0 mt-2 d-flex align-items-center' {...FEE_COLS[0]}>
        <TextClickCheck disabled={true} className={'invisible form-check'} inline={true} onSelected={()=>undefined}/>
        {contentA}
      </Col>
      <Col className='px-0 mt-2' {...FEE_COLS[1]}>
        {contentB}
      </Col>
    </Row>
  </Container>
  {/* Yeah this is ugly, but it gets the correct spacing because it's the same element used by the buttons */}
  <div className='flex-grow-0 invisible ms-1'><CollectionRemoveButton onRemove={console.error} removable={false}/></div>
</div>;

const marketingTipText = 'Specify services, including advertising and marketing amounts to be charged.';

type FixedRowDefinition = {
  cost: number,
  renderer: JSX.Element,
  enableValue?: boolean // Don't want to subscribe to the value in this function here, as this is supposed to be a general purpose component. While individual checkboxes will listen directly, the calculations done in this function will refer to this value instead
};

export function FeesStack ({ title, totalLabel, noAdd, sectionBookmark, requiredTipText, fixedRows,...transactionProps }: TransactionConsumerProps & {
  requiredTipText?: string,
  title: string | React.ReactElement,
  totalLabel: string,
  noAdd?: boolean,
  sectionBookmark?: string,
  fixedRows?: FixedRowDefinition[],
}) {
  const { formName: formCode } = useContext(FormContext);
  const { value: feeRows, fullPath } = useLightweightTransaction(transactionProps); // This is not the call that initialises the default values. That would happen in the useTransactionField call in the CollectionEditor
  const { value: marketingParent, fullPath: marketingFeesOptionsPath } = useLightweightTransaction({ myPath: 'marketingFeesOptions' });
  const { updateDraft: updateRoot } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { showFocusErrors, focusErrList } = useContext(WizardDisplayContext);
  const errorFocusTarget = composeErrorPathClassName(fullPath, sectionBookmark);
  const showSectionError = showFocusErrors && focusErrList.includes(errorFocusTarget);
  const validValues = [
    ...(((feeRows??[]) as {itemCost: number|string|undefined}[])
      .map(r=>r.itemCost)
      .filter(r=>typeof r === 'number')),
    ...(fixedRows??[])
      .filter(r=>r.enableValue && typeof r.cost === 'number')
      .map(r=>r.cost)
  ].filter(Predicate.isNotNullish);

  const formFamilyCode = FormTypes[formCode].formFamily;
  const familyCodePath = `family_${formFamilyCode}`;

  const annexureUploaded = !!marketingParent?.externalFeeScheduleAnnexureRef?.[familyCodePath];

  const feesDisabled = marketingParent?.noFees || annexureUploaded;
  const costSum = feesDisabled ? 0 : validValues?.reduce((acc, cv)=>acc+cv, 0) || 0;

  useEffect(()=>{
    updateRoot?.((draft)=>{
      const { parent, indexer } = getPathParentAndIndex(marketingFeesOptionsPath, draft);
      if (!parent[indexer]) {
        parent[indexer] = {};
      }
      const optionsParent = parent[indexer] as MarketingFeesOptions;
      if (typeof costSum !== 'number') {
        optionsParent.denormalisedTotal = 0;
        return;
      }
      optionsParent.denormalisedTotal = costSum;
    });
  }, [costSum]);

  const fixedRowsRender = useMemo(()=>(fixedRows??[]).map(row=>row.renderer), [fixedRows]);

  return <div className={clsJn('subsection scrollspy-target', errorFocusTarget)} data-focus-path={sectionBookmark}>

    <TooltipWhenRequired title={requiredTipText??''} required={!!requiredTipText}>
      <div className='fs-4 d-flex align-items-center'>{title}<sup className='fs-5' style={{ color: 'red' }}> *</sup></div>
    </TooltipWhenRequired>
    {showSectionError && <div className='d-block invalid-feedback mb-3'>{requiredTipText}</div>}
    <div className='mt-2 d-flex flex-wrap'>
      {!annexureUploaded && <div className='mt-1 me-3'>
        <WrField.BoolCheck
          name='marketingFeesOptions.noFees'
          parentPath='marketingFeesOptions'
          myPath='noFees'
          inline={true}
          label='No marketing costs to be charged by the agent'
        />
      </div>}
      {!marketingParent?.noFees && <SectionReplacementAnnexure
        parentPath={'marketingFeesOptions'}
        myPath={'externalFeeScheduleAnnexureRef'}
        buttonText='Upload Marketing Schedule'
        documentTitle='Marketing Schedule'
      />}
    </div>
    {!feesDisabled && <><RowReplication
      contentA={<span className='ps-2'>Item Description</span>}
      contentB={<div className='w-100 pe-2 text-end'>Item Price</div>}
    />
    {fixedRowsRender}
    <CollectionEditor myPath={fullPath} autoAddNew={!noAdd} autoAddFirst={false} allowAdd={!noAdd} childItemRenderer={FeeLineItem} />
    <RowReplication
      contentA={<div className='w-100 pe-3 text-end field-padding-replication '>{totalLabel}</div>}
      contentB={<div className='text-end total-box'>{canonicalisers.audWithNegative(costSum.toString()).display}</div>}
    /></>}
  </div>;
}

export function MarketingTemplateSectionOld ({ sectionBookmark, ...transactionProps }: TransactionConsumerProps & {sectionBookmark?: string}) {
  const { value: feeRows, fullPath } = useLightweightTransaction(transactionProps);
  const errorFocusTarget = composeErrorPathClassName(fullPath, sectionBookmark);
  const validValues = ((feeRows as Maybe<{itemCost: number|string|undefined, enable: boolean}[]>)?.map(r=> r.enable ? r.itemCost : 0).filter(r=>typeof r === 'number')) as undefined | number[];
  const costSum = validValues?.reduce((acc, cv)=>acc+cv, 0) || 0;
  return <div className={clsJn('subsection', errorFocusTarget)} >
    <CollectionEditor myPath={fullPath} autoAddNew={true} autoAddFirst={false} allowAdd={true} sectionProperty={'section'} emptyRecordProperties={['enable', 'itemDesc', 'itemCost']} childItemRenderer={MarketingTemplateLineItem} />
    <div className={'w-100 d-flex'}>
      <div className='w-100 pe-3 text-end field-padding-replication fw-bold'>Total</div>
      <div className='text-end total-box fw-bold' style={{ marginRight: '30px', width: 'fit-content' }}>{canonicalisers.audWithNegative(costSum.toString()).display}</div>
    </div>
  </div>;
}

export function MarketingTemplateSection ({ sectionBookmark, ...transactionProps }: TransactionConsumerProps & {sectionBookmark?: string}) {
  const { value: template, fullPath } = useLightweightTransaction(transactionProps);
  const errorFocusTarget = composeErrorPathClassName(fullPath, sectionBookmark);
  return <div id='settingsMarketingTemplates' className={clsJn('subsection', errorFocusTarget)} >

    <CollectionEditor
      parentPath={fullPath}
      myPath={'sections'}
      autoAddFirst={false}
      autoAddNew={false}
      allowAdd={true}
      overrideAdd={[
        {
          label: 'Add Section',
          fn: ()=>({ type: MarketingTemplateSectionType.individual })
        },
        {
          label: 'Add Package',
          fn: ()=>({ type: MarketingTemplateSectionType.package })
        }
      ]}
      allowDragAndDrop={false}
      itemNoun={'Section'}
      childItemRenderer={MarketingSection}
      hasHeaderRow={false}
      childProps={{
        editMode: false,
        template
      }}
    />
  </div>;
}

function PressPromotionSection() {
  const { value: pressPromo, fullPath } = useLightweightTransaction<PressBudgetType>({ myPath: 'pressBudget' });
  return <div className='subsection scrollspy-target' data-focus-path="subsection-periodic-press">
    <div className='fs-4'>Periodic Press Promotion Budget<ShowGuidanceNotesButton noteId='periodicPress' /><sup className='fs-5' style={{ color: 'red' }}> *</sup></div>
    <div className="w-100 mt-2 d-flex">
      <div className='flex-grow-1'>
        <WrField.CheckRadio
          label=''
          radioType='checkbox'
          options={boolApplicableOpts}
          valueType='boolean'
          inline={true}
          parentPath={fullPath}
          name='enable'
          myPath='enable'
        />
      </div>
    </div>
    {Predicate.boolFalse(pressPromo?.enable) && <div className='mt-3 ms-3' style={{ flex: '1 1 300px' }}>
      <WrField.CheckRadio
        label=''
        radioType='radio'
        options={{ true: 'Strike out section in document', false: 'Remove section from document' }}
        valueType='boolean'
        inline={false}
        name='pressBudget.strike'
        myPath='pressBudget.strike'
      />
    </div>}
    {pressPromo?.enable && <div className="w-100 mt-2 d-flex">
      <WrField.CheckRadio
        label='Will this be in addition to the fees listed above?'
        radioType='checkbox'
        options={boolYesNoOpts}
        valueType='boolean'
        inline={true}
        parentPath={fullPath}
        name='additional'
        myPath='additional'
      />
    </div>}
    {pressPromo?.enable && <div className="w-100 mt-2 d-flex">

      <WrField.CheckRadio
        label='When should this promotion begin?'
        radioType='checkbox'
        options={pressBeginOpts}
        inline={false}
        parentPath={fullPath}
        name='start'
        myPath='start'
      />
    </div>}
    {pressPromo?.enable && pressPromo?.start && pressPromo?.start !== 'na' && <>
      {pressPromo?.start === 'other' && <div className="w-100 mt-2 d-flex"><div className='flex-shrink-1 flex-grow-1' style={{ maxWidth: '600px' }}>
        <WrField.Control
          parentPath={fullPath}
          name='startOther'
          myPath='startOther'
          label='Other'
        />
      </div></div>}
      <div className="w-100 mt-2 d-flex flex-wrap">
        <div className='flex-grow-1' style={{ maxWidth: '300px', minWidth: '120px' }}>
          <WrField.Control
            parentPath={fullPath}
            name='expenditure'
            myPath='expenditure'
            label='Approved expenditure'
          />
        </div>
        <div className='d-flex flex-grow-1'>
          <div className='flex-grow-0 mx-3 d-flex align-items-center'>per</div>
          <div className='flex-grow-1' style={{ minWidth: '150px', maxWidth: '252px' }}>
            <WrField.Select
              options={expenditurePeriods}
              parentPath={fullPath}
              name='expPeriod'
              myPath='expPeriod'
              label='Period'
            />
          </div>
        </div>
      </div>
      <div className="w-100 mt-2 d-flex flex-wrap">
        <div className='flex-grow-0'>
          <WrField.CheckRadio
            label='When should this promotion end?'
            radioType='checkbox'
            options={pressEndOpts}
            valueType='boolean'
            inline={true}
            parentPath={fullPath}
            name='endAgency'
            myPath='endAgency'
          />
        </div>
        {typeof pressPromo?.endAgency === 'boolean' && !pressPromo?.endAgency && <div className='flex-grow-0'>
          <WrField.Control
            type='date'
            useCanonical={true}
            parentPath={fullPath}
            name='otherEnd'
            myPath='otherEnd'
            label='Other end date'
            dateFromToday={true}
          />
        </div>}
      </div>
    </>}
  </div>;
}

const disclosureTipText = 'Specify whether there are other benefits, their nature and source';

function DisclosureOfBenefitsSection() {
  const { value: benefits, fullPath } = useLightweightTransaction<DisclosureOfBenefitsType>({ myPath: 'benefits' });
  const { showFocusErrors, focusErrList } = useContext(WizardDisplayContext);
  const [optedToEditBenefits, setOptedToEditBenefits] = useState(false);
  const benefitsError = focusErrList.some(err=>err.startsWith(composeErrorPathClassName(fullPath,'')));
  const editBenefits = !benefits?.template || optedToEditBenefits || benefitsError;

  useEffect(()=>{
    if (!benefitsError) return;
    setOptedToEditBenefits(true);
  }, [benefitsError]);

  const comparableErrorFocusTarget = composeErrorPathClassName(['benefits', 'benefits'], '');
  const showBenefitSectionError = showFocusErrors && focusErrList.includes(comparableErrorFocusTarget);

  return <div className='subsection scrollspy-target' data-focus-path="subsection-disclosure-of-benefits">
    <TooltipWhenRequired title={disclosureTipText} required={true}>
      <div className='fs-4'>Disclosure of Benefits<ShowGuidanceNotesButton noteId='disclosureOfBenefits' /><sup className='fs-5' style={{ color: 'red' }}> *</sup></div>
    </TooltipWhenRequired>
    {showBenefitSectionError && <div className='d-block invalid-feedback mb-3'>{disclosureTipText}</div>}
    <div className="w-100 mt-2 d-flex">
      <div className='flex-grow-1'>
        <WrField.CheckRadio
          label='Are Benefits in addition to those described in General Condition 8 to be disclosed to the Vendor?'
          radioType='checkbox'
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          parentPath={fullPath}
          name='enable'
          myPath='enable'
        />
      </div>
    </div>
    <div className='w-100 mt-2 d-flex'>
      <div className='flex-grow-1'>
        <DisclosureOfBenefitTemplateSelector
          parentPath={fullPath}
        />
      </div>
    </div>
    {Predicate.isTruthy(benefits?.enable) && !editBenefits && <Button variant='link' onClick={() => setOptedToEditBenefits(true)}>Edit benefits</Button>}
    {Predicate.isTruthy(benefits?.enable) && editBenefits && <div className='mt-2' style={{ flex: '1 1 300px' }}>
      <CollectionEditor
        parentPath={fullPath}
        myPath={'benefits'}
        autoAddFirst={false}
        autoAddNew={true}
        childItemRenderer={BenefitLineItem}
        hasHeaderRow={true}
        childProps={{
          columns: [
            { path: 'itemDesc', header: 'Nature of the Benefit*', label: 'Nature' },
            { path: 'itemSource', header: 'Source of the Benefit', label: 'Source' },
            { path: 'itemCost', header: 'Benefit amount**', label: 'Amount' },
            { path: 'itemRecipient', header: 'Recipient and capacity***', label: 'Recipient' }
          ]
        }}/>
      <p>
        * A description of the benefit. Important context information should be written here.
      </p>
      <p>
        ** The amount (or estimated amount or value) in dollars, pursuant to section 24C (4) of the {FormatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.
      </p>
      <p>
        *** Refers to the capacity in which the person receives the benefit. e.g. as an agent, a financier, mortgage
        broker, lawyer.
      </p>
    </div>}
  </div>;
}

function AdminSection() {
  const { value: admin, fullPath } = useLightweightTransaction<AdminFeeType>({ myPath: 'adminFee' });
  return <div className='subsection scrollspy-target' data-focus-path='subsection-admin-fee'>
    <div className='fs-4 d-flex align-items-center'>Administration Fee <ShowGuidanceNotesButton
      noteId='administrationFee'/></div>
    <div className='w-100 mt-2 d-flex'>
      <div className='flex-grow-1'>
        <WrField.CheckRadio
          label='Will an Administration Fee be charged?'
          radioType='checkbox'
          options={boolApplicableOpts}
          valueType='boolean'
          inline={true}
          parentPath={fullPath}
          name='enable'
          myPath='enable'
        />
      </div>
    </div>
    {Predicate.boolFalse(admin?.enable) && <div className='mt-3' style={{ flex: '1 0 200px' }}>
      <WrField.CheckRadio
        label=''
        radioType='radio'
        options={{ true: 'Strike out section in document', false: 'Remove section from document' }}
        valueType='boolean'
        inline={false}
        name='adminFee.strike'
        myPath='adminFee.strike'
      />
    </div>}
    {Predicate.isTruthy(admin?.enable) && <>
      <div className='w-100 mt-2'>
        <WrField.Control
          textArea={{ rows: 3 }}
          label='Fee Description'
          parentPath={fullPath}
          myPath='templatePrice'
          name='templatePrice'
        />
      </div>
      <div className='mt-2 d-flex'>
        <WrField.Control
          parentPath={fullPath}
          myPath='setPrice'
          name='setPrice'
          placeholder='$'
        />
      </div>
    </>}
  </div>;
}

function AuctionSection(props: {
  saleMethod: SaleMethod|undefined;
}) {
  const [ auctionFieldHasFocus, setAuctionFieldHasFocus ] = useState(false);
  const isAuction = props.saleMethod === SaleMethod.Auction;
  const saleMethodNotSet = !props.saleMethod;
  const { value: auctionFeeApplicable, handleUpdate: setAuctionApplicable } = useTransactionField({ parentPath: 'auctionFeeSwitches', myPath: 'applicable' });

  // We shall not perform any automatic setting of values here, as an effect will fire
  // on every page load, but the user may have said, auction fee not applicable for an
  // option. So we need to set this when the sale method value changes only.

  if (!(saleMethodNotSet || isAuction || auctionFieldHasFocus)) return null;

  return <div className='subsection scrollspy-target' data-focus-path='subsection-auction-fee'>
    <div className='fs-4 d-flex align-items-center'>Auction Fee <ShowGuidanceNotesButton noteId='auctionFee'/>
      <RequiredAsterisk/></div>
    <div className="w-100 mt-2 d-flex">
      <div className='flex-grow-1'>
        <WrField.CheckRadio
          label='Will an Auction Fee be charged?'
          radioType='checkbox'
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          name='auctionFeeSwitches.applicable'
          parentPath='auctionFeeSwitches'
          myPath='applicable'
        />
      </div>
    </div>
    {auctionFeeApplicable && <div className='w-100 mt-2'>
      <WrField.Control
        textArea={{ rows: 3 }}
        label='Fee Description'
        myPath='auctionFeeSwitches.feeDesc'
        name='auctionFeeSwitches.feeDesc'
      />
    </div>}
    {auctionFeeApplicable && <div className='w-100 mt-2 d-flex'>
      <WrField.Control
        myPath='auctionFee'
        name='auctionFee'
        placeholder='$'
        onFocus={()=>setAuctionFieldHasFocus(true)}
        onBlur={()=>setAuctionFieldHasFocus(false)}
      />
    </div>}
    {Predicate.boolFalse(auctionFeeApplicable) && <div className='mt-3' style={{ flex: '1 0 200px' }}>
      <WrField.CheckRadio
        label=''
        radioType='radio'
        options={{ true: 'Strike out section in document', false: 'Remove section from document' }}
        valueType='boolean'
        inline={false}
        name='auctionFeeSwitches.strike'
        parentPath='auctionFeeSwitches'
        myPath='strike'
      />
    </div>}
  </div>;
}

/**
 * @deprecated - use new marketing templates
 */
export type ReaformsMarketingTemplateItem = {
  description: string
  amount: string
  enabled: string
  locked: string
};
/**
 * @deprecated - use new marketing templates
 */
export type ReaformsMarketingTemplate = {
  EntityID: number
  TemplateID: number
  TemplateName: string
  PackageTitle: string
  PackageSubtitle: string
  PackageAgentName: string
  PropertyEssentials: string
  OnlineAdvertising: string
  AdditionalExtras: string
};

export function ManualFeesSection ({ setHeaderActions } : { setHeaderActions?: SetHeaderActionsFn }) {
  const { formRules } = useContext(FormContext);
  const { value: sale } = useLightweightTransaction<SaleDetailsType>({ myPath: 'sale' });
  const marketingTemplatePath = ['marketingTemplate'];
  const { value: marketingTemplate } = useTransactionField<MarketingTemplate | MarketingTemplateOld>({ myPath: marketingTemplatePath });
  const { updateDraft: updateRoot } = useYdocBinder<MaterialisedPropertyData>({ path: marketingTemplatePath.slice(0,-1) });
  const { handleInsert: insertMarketingFees, handleClear: clearMarketingFees, fullPath: lineItemsPath } = useTransactionField({ myPath: 'marketingFees' });
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({ bindToMetaKey: true });
  const entitySettings = useEntity(meta?.entity?.id);

  useEffect(() => {
    setHeaderActions?.(generateHeaderButtons(entitySettings?.marketingTemplates??[]));
  }, [entitySettings?.marketingTemplates]);

  const generateHeaderButtons = (templates: MarketingTemplate[]) => {
    // If a marketing template exists, it will become the primary template, otherwise default to Default template
    const defaultId = undefined;
    const primaryAction = (marketingTemplate && marketingTemplate?.id) ?? defaultId;
    const items = [...templates, { name: 'Default', id: undefined, items: [] }];
    return mapValues(keyBy(items, t => t.id), t => {
      return {
        label: t.name,
        onClick: () => applyMarketingTemplate(t),
        isPrimary: t.id === primaryAction
      };
    });
  };

  const applyMarketingTemplate = (template: MarketingTemplate) => {
    updateRoot?.(draft=>{
      if (template.id) {
        draft.marketingTemplate = {
          ...cloneDeep(template)
        };
      } else {
        delete draft.marketingTemplate;
      }
    });
    clearMarketingFees();
    !template?.id && insertMarketingFees(getValidationDefnByPath(normalisePathToStrArray(lineItemsPath), formRules, false)?._prefill?.entries??[]);
  };

  return <>
    {typeof marketingTemplate?.id === 'string'
      ? <MarketingTemplateSection
        myPath='marketingTemplate'
        sectionBookmark='subsection-marketing-fees'
      />
      : 'enable' in (marketingTemplate||{}) && (marketingTemplate as MarketingTemplateOld).enable
        ? <MarketingTemplateSectionOld
          myPath='marketingFees'
          sectionBookmark='subsection-marketing-fees'
        />
        : <FeesStack
          title="Vendor’s Marketing Costs"
          myPath='marketingFees'
          totalLabel="APPROVED AMOUNT (inclusive of GST)"
          sectionBookmark='subsection-marketing-fees'
          requiredTipText={marketingTipText}
        />
    }
    <PressPromotionSection />
    <AdminSection/>
    <AuctionSection
      saleMethod={sale?.saleMethod}
    />
    <TransactionFeeSection />
    <Form1AndSearchesSection />
    <DisclosureOfBenefitsSection />
  </>;
}
