import { CollectionEditor, EditorListChildProps } from '../form/CollectionEditor';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { useTransactionField } from '../../hooks/useTransactionField';
import clsJn from '@property-folders/common/util/classNameJoin';
import { InputGroup } from 'react-bootstrap';
import { MarketingTemplate, MarketingTemplateItemState, MarketingTemplateSection, MarketingTemplateSectionType } from '@property-folders/contract/yjs-schema/entity-settings';
import { WrField } from '../form/CommonComponentWrappers';
import { CollectionRemoveButton } from '../form/CollectionRemoveButton';
import React, { useEffect } from 'react';
import { MarketingLineItem } from './MarketingLineItem';
import { Link } from 'react-router-dom';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { Icon } from '../Icon';

export function MarketingSection({ editMode, removable, template, ...restProps }: EditorListChildProps & TransactionConsumerProps & { editMode?: boolean, template: MarketingTemplate } )  {
  const { value: section, fullPath, handleRemove } = useTransactionField<MarketingTemplateSection>(restProps);
  const { updateDraft: updateSection } = useYdocBinder<MarketingTemplateSection>({ path: fullPath });
  const { type, locked, id, defaultState } = section || {};
  const disabled = !editMode && locked;
  const isPackage = type === MarketingTemplateSectionType.package;
  const isRequired = defaultState === MarketingTemplateItemState.required;

  const handleSetCustomised = () => {
    updateSection?.(draft => {
      draft.lineColour = template.sectionLineColour;
      draft.textColour = template.sectionTextColour;
      draft.backgroundColour = template.sectionBackgroundColour;
    });
  };

  useEffect(()=>{
    if (!updateSection || !section) return;
    updateSection(draft => {
      if (!draft) return;
      draft.enabled = (defaultState || MarketingTemplateItemState.unchecked) !== MarketingTemplateItemState.unchecked;
    });
  }, [defaultState]);

  const defaultStateCheckbox = <WrField.Select
    name="defaultState"
    optionRender={option => {
      return <div>
        <Icon
          name={option.name === MarketingTemplateItemState.unchecked ? 'check_box_outline_blank' : 'check_box'}
          variant="outlined"
          icoClass={clsJn('me-1 fs-4 mb-0', option.name === MarketingTemplateItemState.required && 'required-checkbox')}>
        </Icon>
        {option.label}
      </div>;
    }}
    inputRender={(inputProps) => {
      return <Icon
        name={defaultState === MarketingTemplateItemState.unchecked ? 'check_box_outline_blank' : 'check_box'}
        variant="outlined"
        onClick={(e) => {
          inputProps.onClick?.(e);
        }}
        icoClass={clsJn('fs-4 cursor-pointer default-checkbox', isRequired && 'required-checkbox')}
        title={'Package default'}>
      </Icon>;
    }}
    options={[{
      name: MarketingTemplateItemState.unchecked,
      label: 'Unchecked'
    }, {
      name: MarketingTemplateItemState.checked,
      label: 'Checked'
    }, { name: MarketingTemplateItemState.required, label: 'Required' }]}
    parentPath={fullPath}
    myPath="defaultState"
    canClear={false}
    tight={true}
  />;

  return (
    <div className={clsJn('mb-4 w-100 py-2 pe-2 position-relative section scrollspy-target', locked && 'locked-section', editMode && 'edit-mode', isPackage && 'package', editMode && 'px-2')} data-focus-path={`subsection-sub-${id}`}>
      <div className={clsJn('mb-3 mt-1 px-2 section-header')}>
        <div className={clsJn('d-flex flex-row align-items-center pe-0 flex-wrap gap-2', editMode && 'ms-2')}>
          <div className={'d-flex flex-grow-1 flex-wrap'}>
            <InputGroup className="d-flex flex-nowrap">
              <div className={'d-flex align-items-center gap-2 flex-grow-1'}>
                {isPackage && !editMode && <WrField.BoolCheck disabled={isRequired} className={'me-0'} name="enabled" parentPath={fullPath} myPath="enabled"/>}
                {isPackage && editMode && defaultStateCheckbox}
                <WrField.Control parentPath={fullPath} myPath={'name'} name={'name'} disabled={disabled} containerClassName={'flex-grow-1'} label={`${isPackage ? 'Package' : 'Section'} Header`}/>
              </div>

              {editMode && section?.lineColour &&
                <div className={'d-flex'}>
                  <InputGroup.Text>
                    <WrField.ColourPicker text={<Icon name="title" pack="material-symbols"/>} name={'Header text colour'} parentPath={fullPath} myPath="textColour" defaultValue={'#404040'}/>
                  </InputGroup.Text>
                  <InputGroup.Text>
                    <WrField.ColourPicker text={<Icon name="colors" pack="material-symbols"/>} name={'Header background colour'} parentPath={fullPath} myPath="backgroundColour" defaultValue={'#da6027'}/>
                  </InputGroup.Text>
                  <InputGroup.Text>
                    <WrField.ColourPicker text={<Icon name="horizontal_rule" pack="material-symbols"/>} name={'Header line colour'} parentPath={fullPath} myPath="lineColour" defaultValue={'#404040'}/>
                  </InputGroup.Text>
                </div>
              }
              {editMode && !section?.lineColour && <InputGroup.Text>
                <Link onClick={handleSetCustomised}>
                  <Icon name={'palette'} variant="outlined" title={'Customise colours'} icoClass={'fs-4 cursor-pointer icon-palette'} />
                </Link>
              </InputGroup.Text>}
            </InputGroup>
          </div>

          {editMode && <WrField.Select
            name="type"
            options={[{ name: MarketingTemplateSectionType.individual, label: 'Individual' }, { name: MarketingTemplateSectionType.package, label: 'Package' }]}
            parentPath={fullPath}
            myPath="type"
            label={'Type'}
            canClear={false}
            tight={true}
            style={{ width: '100px' }}
          />}

          {isPackage &&
            <WrField.Control parentPath={fullPath} myPath={'price'} disabled={disabled} name={'price'} style={{ maxWidth: '110px' }} label={'Price'}/>}

          {handleRemove && <div className={'d-flex ms-2 position-absolute'} title={'Lock package content'} style={{ right: 0, top: '18px' }}>
            {isPackage && editMode &&
              <WrField.BoolCheck
                containerClass={'d-flex align-items-center ms-2'}
                icoClass={'fs-4 section-lock'}
                iconUnchecked={'lock_open'}
                iconChecked={'lock'}
                name='locked'
                parentPath={fullPath}
                myPath='locked'
              />}
            {((!disabled && !isRequired) || editMode) && <CollectionRemoveButton className={'remove-button me-2'} removable={!!removable && !disabled} onRemove={handleRemove} />}
          </div>}
        </div>
      </div>

      <div className={clsJn(!editMode  && 'ms-4')}>
        <CollectionEditor
          parentPath={fullPath}
          myPath={'items'}
          autoAddNew={!disabled}
          allowAdd={!disabled}
          overrideAdd={()=>({
            defaultState: MarketingTemplateItemState.unchecked,
            enabled: false
          })}
          allowDragAndDrop={editMode}
          dragAndDropConfig={{
            handleClass: 'childDragHandle'
          }}
          childItemRenderer={MarketingLineItem}
          hasHeaderRow={false}
          childProps={{
            editMode,
            type: type,
            parentLocked: locked
          }}
        />
      </div>
    </div>
  );
}
